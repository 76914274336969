<template>
  <!-- 部门管理 -->
  <div>
    <Header title="部门管理" index="首页" titleOne="组织管理" beforeTitle="部门管理" />
    <div class="content">
      <div class="topUsers">
        <div>
          <el-input
            clearable
            v-model="classParams.keywords"
            placeholder="请输入部门名称"
            @keyup.enter.native="handleSearch()"
          ></el-input>
          <el-button class="search" @click="handleSearch()">查询</el-button>
        </div>
        <el-button plain @click="addClass()" class="add">新增</el-button>
      </div>
      <el-table
        v-loading="loading"
        element-loading-text="正在加载中···"
        :data="classList.data"
        style="width: 100%"
      >
        <el-table-column prop="name" label="部门名称" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="p_name" label="上级部门" :show-overflow-tooltip="true"></el-table-column>
        <!-- <el-table-column prop="leader_id" label="部门主管（与角色的部门无关）" :show-overflow-tooltip="true"></el-table-column> -->
        <el-table-column prop="operation" label="操作">
          <template slot-scope="scope">
            <el-button class="edit" size="mini" @click="editClass(scope.row.id)">编辑</el-button>
            <el-button class="del" size="mini" @click="delClass(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="classParams.page"
          :page-sizes="[1, 10, 20, 40]"
          :page-size="classParams.limit"
          layout="sizes, prev, pager, next,total,jumper"
          :total="classList.total"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      token: "",
      loading: false,//部门管理列表加载动画
      classList: [],//部门管理列表存储
      classParams: {
        keywords: '',//搜索关键词
        page: 1,
        limit: 10,
      }
    }
  },
  created () {
    this.token = localStorage.getItem('token')
    this.getClassList()
  },
  methods: {
    getClassList () {
      // 部门管理列表获取
      this.axios.get('/api/organization/list', { params: { token: this.token } }).then((res) => {
        this.classList = res.data
      })
    },
    handleSearch () {
      console.log(this.classParams)
      this.axios.get('/api/organization/list', { params: { page: '', limit: this.classParams.limit, keywords: this.classParams.keywords } }).then((res) => {
        this.classList = res.data
        if (res.code == 0) {
          this.$message.warning(res.message)
          this.classList = []
        }
      })
    },
    addClass () {
      this.$router.push({ path: '/organization/class_admin/add_edit' })
    },
    editClass (id) {
      this.$router.push({ path: '/organization/class_admin/add_edit', query: { id: id } })
    },
    delClass (id) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.delete('/api/organization/delete', { params: { id: id } }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getClassList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    handleSizeChange (val) {
      this.classParams.limit = val;
      this.axios.get('/api/organization/list', { params: { ...this.classParams } }).then((res) => {
        this.classList = res.data
      })
    },
    handleCurrentChange (val) {
      this.classParams.page = val;
      this.axios.get('/api/organization/list', { params: { ...this.classParams } }).then((res) => {
        this.classList = res.data
      })
    },
  },
}
</script>

<style>
</style>